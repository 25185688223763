import { csrfToken, csrfParam } from 'harvest'
import { forwardRef } from 'preact/compat'

export const Form = forwardRef(function Form(props, ref) {
  const { children, method, ...rest } = props
  const get = !method || method?.toLowerCase() === 'get'

  return (
    <form method={get ? null : 'post'} ref={ref} {...rest}>
      <input name="utf8" type="hidden" value="✓" />
      {get ? null : <input name="_method" type="hidden" value={method} />}
      {csrfParam() && csrfToken() && (
        <input name={csrfParam()} type="hidden" value={csrfToken()} />
      )}
      {children}
    </form>
  )
})
