const cache = {}

const from_cache_or_island = (name) =>
  cache[name] || (cache[name] = parse_data_island(name))

const parse_data_island = (name) => {
  const island = document.getElementById(`${name}-data-island`)
  if (!island) {
    return ferry_data_from_island_warning(name)
  }
  return JSON.parse(island.innerHTML)
}

const ferry_data_from_island_warning = (name) => {
  const message =
    `We encountered a problem loading your data (bootstrap ${name}).
This can occur when accessing Harvest through a proxy or when using
certain browser extensions that removes certain tags from the page. Please
contact your IT staff or Harvest for more details.`.replace(/\n/g, ' ')
  alert(message) // eslint-disable-line no-alert
  throw new Error(message)
}

const underscoreize = (object) => {
  const results = {}
  for (const [key, value] of Object.entries(object)) {
    results[key.replace(/-/g, '_')] = value
  }
  return results
}

export default (...names) => {
  const results = {}
  for (const name of names) {
    results[name] = from_cache_or_island(name)
  }

  if (names.length === 1) {
    return results[names[0]]
  } else {
    return underscoreize(results)
  }
}
