/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-other-methods */
import { Button, Menu, MenuButton, MenuLink } from '@harvesthq/porchlight'
import { pageData as page_data } from 'harvest'
import { createRoot } from 'preact/compat/client'

import ferry_data_from_island from './ext/data_island'
import {
  StripePaymentForm,
  StripeRecurringPaymentForm,
} from './ext/stripe_elements_form'
import { post } from './fetch-json'
import $ from './globals/jquery'

import './ext/print'
import '@harvesthq/porchlight/js/tooltip'
import './ext/toggleform'
import './ext/form_loader'

const PayInvoiceMenu = () => {
  const { available_payment_methods, show_recurring, pay_pal_url } = page_data
  const onePaymentMethod = available_payment_methods.length === 1
  const singularStripePayment =
    available_payment_methods.length === 2 &&
    available_payment_methods
      .sort()
      .every(
        (value, index) => value === ['stripe_ach', 'stripe_elements'][index]
      )

  function PaymentButton({ children, ...rest }) {
    if (onePaymentMethod || singularStripePayment) {
      return (
        <Button variant="primary" {...rest}>
          {children}
        </Button>
      )
    } else {
      return <MenuButton {...rest}>{children}</MenuButton>
    }
  }

  function PaymentLink({ children, ...rest }) {
    if (onePaymentMethod) {
      return (
        <a className="pds-button pds-button-primary" {...rest}>
          {children}
        </a>
      )
    } else {
      return <MenuLink {...rest}>{children}</MenuLink>
    }
  }

  const buttons = (
    <>
      {singularStripePayment && (
        <PaymentButton
          data-showform="stripe-elements-form"
          data-analytics-element-id="client-invoice-pay-with-stripe"
          onClick={() => {
            !show_recurring
              ? fetchPaymentIntent('card', show_recurring)
              : setPreferredPaymentMethod('card')
          }}>
          Pay invoice
        </PaymentButton>
      )}
      {!singularStripePayment &&
        available_payment_methods.includes('stripe_elements') && (
          <PaymentButton
            data-showform="stripe-elements-form"
            data-analytics-element-id="client-invoice-pay-with-credit-card"
            onClick={() => {
              !show_recurring
                ? fetchPaymentIntent('card', show_recurring)
                : setPreferredPaymentMethod('card')
            }}>
            {onePaymentMethod ? 'Pay invoice' : 'Pay with credit card'}
          </PaymentButton>
        )}
      {!singularStripePayment &&
        available_payment_methods.includes('stripe_ach') && (
          <PaymentButton
            data-showform="stripe-elements-form"
            id={!show_recurring ? 'linkButton' : ''}
            data-analytics-element-id="client-invoice-pay-with-bank-transfer"
            onClick={() => {
              !show_recurring
                ? fetchPaymentIntent('us_bank_account', show_recurring)
                : setPreferredPaymentMethod('us_bank_account')
            }}>
            {onePaymentMethod ? 'Pay invoice' : 'Pay with bank transfer (ACH)'}
          </PaymentButton>
        )}
      {available_payment_methods.includes('pay_pal') && (
        <PaymentLink
          href={pay_pal_url}
          data-method="post"
          id="pay-with-paypal-button"
          data-analytics-element-id="client-invoice-pay-with-paypal"
          onTouchStart="">
          {onePaymentMethod ? 'Pay invoice' : 'Pay with PayPal'}
        </PaymentLink>
      )}
    </>
  )

  if (onePaymentMethod || singularStripePayment) {
    return buttons
  } else {
    return (
      <Menu buttonLabel="Pay invoice" position="right">
        {buttons}
      </Menu>
    )
  }
}

const fetchPaymentIntent = (paymentMethod, isRecurring) => {
  const invoice_client_key = document.querySelector(
    'input[name="invoice_client_key"]'
  ).value

  post('/stripe_connect/payment_intents', {
    body: JSON.stringify({
      invoice_client_key: invoice_client_key,
      recurring: isRecurring,
    }),
  }).then((data) => {
    if (data.error) {
      document.getElementById('payment-error').textContent = data.error
    } else {
      let stripe_info = ferry_data_from_island('stripe_info')
      stripe_info.client_secret = data.client_secret
      stripe_info.preferred_payment_method = paymentMethod

      if (isRecurring) {
        new StripeRecurringPaymentForm(stripe_info).mount()
      } else {
        new StripePaymentForm(stripe_info).mount()
      }
    }
  })
}

const setPreferredPaymentMethod = (paymentMethod) => {
  document.querySelector('input[name="preferred_payment_method"]').value =
    paymentMethod
}

const setup_stripe_elements = function () {
  let choose_payment_step, stripe_elements_form
  if (
    !(stripe_elements_form = document.getElementById('stripe-elements-form'))
  ) {
    return
  }
  if (
    !(choose_payment_step =
      stripe_elements_form.querySelector('.js-choose-payment'))
  ) {
    return
  }

  const confirm_payment_step = stripe_elements_form.querySelector(
    '.js-confirm-payment'
  )

  const confirm_single_payment_text =
    confirm_payment_step.querySelector('.js-text-single')

  const confirm_multiple_payment_text =
    confirm_payment_step.querySelector('.js-text-multiple')

  choose_payment_step
    .querySelector('.js-continue')
    .addEventListener('click', function () {
      const recurring = document.getElementById(
        'credit_card_recurring_payment_true'
      ).checked
      const paymentMethod = document.querySelector(
        'input[name="preferred_payment_method"]'
      ).value
      fetchPaymentIntent(paymentMethod, recurring)
      confirm_multiple_payment_text.hidden = !recurring
      confirm_single_payment_text.hidden = recurring
      choose_payment_step.hidden = true
      confirm_payment_step.hidden = false
    })
  confirm_payment_step
    .querySelector('.js-cancel')
    .addEventListener('click', function () {
      choose_payment_step.hidden = false
      confirm_payment_step.hidden = true
    })
  $('input[name="credit_card_recurring_payment"]').on('change', function () {
    choose_payment_step.querySelector('.js-continue').disabled = false
  })
}

document.addEventListener('DOMContentLoaded', function () {
  const payMenu = document.getElementById('pay-menu-wrapper')
  if (payMenu) createRoot(payMenu).render(<PayInvoiceMenu />)
  setup_stripe_elements()
})
