import { Button, Dialog, DragSelect } from '@harvesthq/porchlight'
import { pageData, RTKQueryError, toast } from 'harvest'
import { useState } from 'preact/compat'
import { useDispatch, useSelector } from 'react-redux'

import { useCreateExportMutation } from './exports_api'
import {
  setColumns,
  setExporting,
  setFormat,
  selectColumns,
  selectExporting,
  selectFormat,
} from './exports_slice'

const FORMATS = {
  csv: 'CSV',
  xlsx: 'Excel',
  pdf: 'PDF',
}

export function ExportDialog({ selectOptions }) {
  const dispatch = useDispatch()
  const exporting = useSelector(selectExporting)
  const columns = useSelector(selectColumns)
  const format = useSelector(selectFormat)
  const reportOptions = useSelector(
    selectOptions || (() => pageData.report_options)
  )
  const [createExport, { error, reset }] = useCreateExportMutation()
  const optionTypes = ['Included in export', 'Excluded from export']
  const [options, setOptions] = useState(() => {
    return [
      {
        type: optionTypes[0],
        items: [...columns],
      },
      {
        type: optionTypes[1],
        items: [],
      },
    ]
  })

  const resetColumns = () => {
    setOptions([
      { type: optionTypes[0], items: pageData.export_columns },
      { type: optionTypes[1], items: [] },
    ])
    dispatch(setColumns(pageData.export_columns))
  }

  function onClose() {
    dispatch(setExporting(false))
  }

  async function onSubmit(event) {
    event.preventDefault()
    try {
      const { location, message } = await createExport({
        export_format: format,
        details: {
          ...reportOptions,
          columns,
        },
      }).unwrap()

      if (message) {
        toast.success(message)
      } else {
        toast.success('Your export is complete.')
        window.location = location
      }

      dispatch(setExporting(false))
      reset()
    } catch {}
  }

  function changeColumns(options) {
    const [{ items }] = options
    dispatch(setColumns([...items]))
    setOptions(options)
  }

  if (!exporting) return null

  const formats = ['csv', 'xlsx']

  return (
    <Dialog size="md" onClose={onClose} title="Custom export" future>
      <form onSubmit={onSubmit}>
        <RTKQueryError error={error} />
        <label className="pds-label pds-mt-md">Export format</label>
        <div className="pds-flex" id="export-formats-list">
          {formats.map((name) => (
            <div className="pds-choice pds-flex-auto" key={name}>
              <input
                type="radio"
                checked={format === name}
                onChange={() => dispatch(setFormat(name))}
                id={`${name}-format`}
              />
              <label htmlFor={`${name}-format`} className="pds-p-sm">
                {FORMATS[name]}
              </label>
            </div>
          ))}
        </div>
        <div className="pds-my-lg">
          <label className="pds-label pds-mt-md">
            Export columns
            <button
              type="button"
              className="pds-button-link pds-ml-xs pds-text-sm"
              onClick={resetColumns}>
              Include all columns
            </button>
          </label>
          <DragSelect
            options={options}
            onDropCallback={changeColumns}
            containerStyle={{ maxHeight: '344px' }}
          />
        </div>
        <div className="pds-mt-lg">
          <Button type="submit" variant="primary" className="pds-mr-xs">
            Export
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </form>
    </Dialog>
  )
}
