import { toast } from 'harvest'

import { post } from './fetch-json'

export async function exportUrl(url, body) {
  toast.notice('Exporting…')

  const { location, message } = await post(url, { body })

  if (location != null) {
    window.location = location
    toast.success('Your export is complete.')
  } else {
    toast.success(message)
  }
}

if (typeof document !== 'undefined') {
  document.addEventListener('click', async function (event) {
    const element = event.target.closest('[data-export]')
    if (!element) return

    element.disabled = true

    try {
      await exportUrl(element.dataset.export)
    } finally {
      element.disabled = false
    }
  })
}
