import { createSlice } from '@reduxjs/toolkit'
import { pageData } from 'harvest'

function initialState() {
  const { export_columns, can_custom_export } = pageData

  return {
    columns: export_columns,
    format: 'csv',
    exporting: false,
    canCustomExport: can_custom_export,
  }
}

export const exportsSlice = createSlice({
  name: 'exports',
  initialState,
  reducers: {
    setColumns(state, action) {
      state.columns = action.payload
    },
    setExporting(state, action) {
      state.exporting = action.payload
    },
    setFormat(state, action) {
      state.format = action.payload
    },
  },
})

export const { setColumns, setExporting, setFormat } = exportsSlice.actions

export const selectExporting = (state) => state.exports.exporting
export const selectFormat = (state) => state.exports.format
export const selectColumns = (state) => state.exports.columns
export const selectCanCustomExport = (state) => state.exports.canCustomExport

export default exportsSlice.reducer
