import { csrfToken } from 'harvest'
import { createRoot } from 'preact/compat/client'

import dialog from './ext/dialog'
import FetchErrorDialog from './fetch-error'

let dialogElement
if (typeof document !== 'undefined') {
  dialogElement = document.createElement('div')
  dialogElement.className = 'pds-dialog-backdrop'
  dialogElement.setAttribute('aria-hidden', 'true')
}
const root = createRoot(dialogElement)

const onError = (error) => {
  if (error.name === 'AbortError') return
  root.render(<FetchErrorDialog status={error.status} />)
  document.body.appendChild(dialogElement)
  dialog.open(dialogElement)
}

class FetchError extends Error {
  constructor({ body, status }) {
    super(`status: ${status}`)
    this.body = body
    this.name = 'FetchError'
    this.status = status
  }
}

const request = async (url, options = {}) => {
  const headers = options.headers || (options.headers = {})

  headers.accept = 'application/json'
  headers['x-csrf-token'] = csrfToken()

  if (options.body) {
    const isFormData = options.body instanceof FormData
    if (typeof options.body !== 'string' && !isFormData) {
      options.body = JSON.stringify(options.body)
    }
    if (!isFormData) {
      headers['content-type'] = 'application/json'
    }
  }

  // required for some versions of Firefox/Safari
  options.credentials = 'same-origin'

  try {
    const response = await window.fetch(url, options)
    const json = /json/.test(response.headers.get('content-type'))
    const body = json ? await response.json() : await response.text()

    if (response.ok) return body
    throw new FetchError({ body, status: response.status })
  } catch (error) {
    ;(options.onError || onError)(error)
    throw error
  }
}

const getMethod = (method) => {
  return (url, options = {}) => {
    options.method = method
    return request(url, options)
  }
}

const exports = {
  delete: getMethod('delete'),
  get: getMethod('get'),
  post: getMethod('post'),
  put: getMethod('put'),
}

export default exports

export const { delete: deleteRequest, get, post, put } = exports
