/* eslint-disable no-sequences, no-void */
const slice = [].slice

import focusable from './focusable'

if (typeof document !== 'undefined') {
  document.addEventListener('keydown', function (event) {
    let container, first, focused, key, last, ref, shiftKey, target
    ;({ key, shiftKey, target } = event)
    if (key !== 'Tab') {
      return
    }
    if (!(focused = target.closest(focusable))) {
      return
    }
    if (!(container = focused.closest('[data-focus-wrap]'))) {
      return
    }
    ;(ref = container.querySelectorAll(focusable)),
      ([first] = ref),
      ([last] = slice.call(ref, -1))
    if (shiftKey && focused === first) {
      event.preventDefault()
      if (last != null) {
        last.focus()
      }
    }
    if (!shiftKey && focused === last) {
      event.preventDefault()
      return first != null ? first.focus() : void 0
    }
  })
}
