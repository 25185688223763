import { csrfToken } from 'harvest'

export const names = () =>
  window.Harvest?.settings?.current_user?.dismissed_tip_names || []

export const is_dismissed = (name) => names().includes(name)

export const dismiss = async (name) => {
  names().push(name)

  await fetch('/dismissed_tips', {
    method: 'post',
    body: JSON.stringify({ name }),
    credentials: 'same-origin', // required for some versions of Firefox/Safari
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-csrf-token': csrfToken(),
    },
  }).catch((error) => {
    throw error
  })
}

export default {
  names,
  is_dismissed,
  dismiss,
}
