let deferredPrompt = null

function promptGooglePlayInstall() {
  if (!deferredPrompt) return

  deferredPrompt.prompt()
  deferredPrompt.userChoice
    .then((choice) => {
      if (choice.outcome === 'accepted') {
        window.heap?.track('Google Play Install Accepted')
      } else {
        window.heap?.track('Google Play Install Canceled')
      }
    })
    .catch((err) => {
      window.Bugsnag?.notify(err)
    })
    .finally(() => {
      deferredPrompt = null
    })
}

window.addEventListener('beforeinstallprompt', (event) => {
  event.preventDefault()
  deferredPrompt = event

  document
    .querySelector('.js-android-install')
    ?.addEventListener('click', (clickEvent) => {
      clickEvent.preventDefault()
      promptGooglePlayInstall()
    })
})
