import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { csrfToken } from 'harvest'

// Wrap a baseQuery function, returning the provided results on the first call
// and subsequently calling through to the provided baseQuery.
//
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
export function withResults(query, results) {
  return async function (args, api, extras) {
    const data = results[api.endpoint]
    if (!data) return query(args, api, extras)
    delete results[api.endpoint]
    return { data }
  }
}

// A custom baseQuery that handles the csrf token and format.
//
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
export function jsonQuery(...args) {
  const fetchQuery = fetchBaseQuery(...args)

  return function (options = {}, ...args) {
    // Options can just be a string sometimes
    if (typeof options === 'string') {
      options = { url: options }
    }

    options.headers ||= {}

    const { headers, method } = options

    // Only accept JSON responses
    headers.accept = 'application/json'

    // Send JSON unless sending FormData
    if (!(options.body instanceof FormData)) {
      headers['content-type'] = 'application/json'
    }

    // Add CSRF token for non-get requests
    if (method?.toUpperCase() !== 'GET') {
      headers['x-csrf-token'] = csrfToken()
    }

    return fetchQuery(options, ...args)
  }
}
