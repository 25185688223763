import './focus_wrap'

import focusable from './focusable'

let source = null

export const open = function (element) {
  element.classList.add('pds-dialog-open')
  element.setAttribute('aria-hidden', false)
  element.dataset.focusWrap = 'true'
  element.querySelector(focusable)?.focus()
  const event = document.createEvent('CustomEvent')
  event.initCustomEvent('dialog-open', true, true, {})
  return element.dispatchEvent(event)
}

export const close = function () {
  for (const element of document.querySelectorAll('.pds-dialog-open')) {
    element.classList.remove('pds-dialog-open')
    element.setAttribute('aria-hidden', true)
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('dialog-close', true, true, {})
    element.dispatchEvent(event)
  }

  return (source = null)
}

if (typeof document !== 'undefined') {
  document.addEventListener('keydown', function ({ key }) {
    if (key !== 'Esc' && key !== 'Escape') {
      return
    }
    if (source != null) {
      source.focus()
    }
    return close()
  })

  document.addEventListener('click', function ({ target }) {
    if (target.closest('[data-close-dialog]')) {
      return close()
    }

    const id = target.closest('[data-open-dialog]')?.dataset?.openDialog
    if (id) {
      close()
      source = target
      return open(document.getElementById(id))
    }
  })
}

export default { open, close }
