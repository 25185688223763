export let pageData = {}

if (typeof document !== 'undefined') {
  const script = document.getElementById('page_data-data-island')
  if (script) pageData = JSON.parse(script.innerHTML)
}

export function setPageData(data) {
  pageData = data
}
