/* eslint-disable no-void */
import focusable from './focusable'
;(function () {
  let source_button = null

  const find_buttons = function (form) {
    if (form != null) {
      return document.querySelectorAll(`[data-showform='${form.id}']`)
    }
  }

  return document.addEventListener('click', function ({ target }) {
    let form, id, ref, ref5
    if (
      (id =
        (ref = target.closest('[data-showform]')) != null
          ? ref.dataset?.showform
          : void 0)
    ) {
      const ref2 = document.querySelectorAll('[data-showform]')
      const results = []

      for (const button of ref2) {
        if (!(form = document.getElementById(button.dataset?.showform))) {
          continue
        }
        form.hidden = id !== form.id
        button.disabled = id === form.id
        if (id === form.id) {
          source_button = button
          results.push(form.querySelector(focusable)?.focus())
        } else {
          results.push(void 0)
        }
      }

      return results
    } else if (
      (id =
        (ref5 = target.closest('[data-hideform]')) != null
          ? ref5.dataset?.hideform
          : void 0)
    ) {
      if (!(form = document.getElementById(id))) {
        return
      }
      form.hidden = true
      const ref7 = find_buttons(form)

      for (const button of ref7) {
        button.disabled = false
      }

      return source_button != null ? source_button.focus() : void 0
    }
  })
})()
