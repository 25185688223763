import { toast } from 'harvest'

document.addEventListener('click', async (event) => {
  if (!event.target.closest('[data-copyable-value]')) return

  event.preventDefault()

  const copier = event.target.closest('[data-copyable-value]')

  try {
    await navigator.clipboard.writeText(copier.dataset.copyableValue)
    toast.success(copier.dataset.copySuccessMessage)
  } catch (err) {
    toast.danger('Error copying link')
  }
})
