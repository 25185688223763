export const buttonLoading = (element) => {
  element.disabled = true
  // icons/icon-loading.svg
  element.innerHTML = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      aria-label="Loading icon"
      class="pds-mr-xs">
      <path d="M9 1.5V4.5" />
      <path opacity="0.5" d="M9 13.5V16.5" />
      <path opacity="0.125" d="M3.69749 3.6975L5.81999 5.82" />
      <path opacity="0.625" d="M12.18 12.18L14.3025 14.3025" />
      <path opacity="0.25" d="M1.5 9H4.5" />
      <path opacity="0.75" d="M13.5 9H16.5" />
      <path opacity="0.375" d="M3.69749 14.3025L5.81999 12.18" />
      <path opacity="0.875" d="M12.18 5.82L14.3025 3.6975" />
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="0.75s"
        calcMode="discrete"
        values="45 0 0; 90 0 0; 135 0 0; 180 0 0; 225 0 0; 270 0 0; 315 0 0; 360 0 0"
        repeatCount="indefinite"
      />
    </svg>
    ${element.dataset.loading}…
  `
}

const submit = function (form) {
  for (const element of form.querySelectorAll('[data-form-spinner]')) {
    element.hidden = false
  }

  for (const element of form.querySelectorAll('[data-disable-on-submit]')) {
    element.disabled = true
  }

  for (const element of form.querySelectorAll('[data-loading]')) {
    buttonLoading(element)
  }
}

document.addEventListener('submit', function ({ target, defaultPrevented }) {
  if (!defaultPrevented) {
    submit(target)
  }
})

export default { submit, buttonLoading }
