/* eslint-disable max-classes-per-file, no-unused-vars */
/*
global Stripe
*/
import { post } from '../fetch-json'

const StripeElementsForm = class StripeElementsForm {
  constructor(options, stripe1) {
    this.client_secret = options.client_secret
    this.paymentReady = this.paymentReady.bind(this)
    this.paymentChanged = this.paymentChanged.bind(this)
    this.submit = this.submit.bind(this)
    this.stripe = stripe1
    this.form = document.getElementById('stripe-elements-form')

    const appearance = {
      // Styled using documentation here: https://stripe.com/docs/elements/appearance-api
      theme: 'none',

      variables: {
        colorPrimary: '#FA5D00',
        colorBackground: '#FFFFFF',
        colorText: '#1D1E1C',
        colorDanger: '#D92F2F',
        colorTextPlaceholder: '#737472',
        colorIconTabMore: '#1D1E1C',
        colorIconTabMoreHover: '#1D1E1C',

        fontFamily: 'Muoto, system-ui, sans-serif',
        fontSizeBase: '15px',
        fontWeightNormal: '400',
        fontWeightMedium: '500',

        spacingUnit: '2px',
        spacingTab: '8px',
        spacingGridRow: '16px',
        spacingGridColumn: '16px',

        borderRadius: '4px',
      },

      rules: {
        '.Tab': {
          border: '1px solid #BBBBBB',
          backgroundColor: 'var(--colorBackground)',
          padding: '8px',
        },

        '.Tab:active': {
          border: '1px solid #737472',
          backgroundColor: 'var(--colorBackground)',
        },

        '.Tab:hover': {
          border: '1px solid #737472',
          backgroundColor: 'var(--colorBackground)',
        },

        '.Tab--selected': {
          border: '1px solid var(--colorPrimary)',
          backgroundColor: '#FFE7D9',
        },

        '.Tab--selected:hover': {
          border: '1px solid var(--colorPrimary)',
          backgroundColor: '#FFE7D9',
        },

        '.TabLabel': {
          paddingTop: '4px',
        },

        '.Label': {
          fontSize: '15px',
          fontWeight: '500',
          color: 'var(--colorText)',
          marginBottom: '4px',
        },

        '.Input': {
          border: '1px solid #BBBBBB',
          backgroundColor: 'var(--colorBackground)',
          padding: '8px',
        },

        '.Input:focus': {
          outline: '1px solid #1D1E1C',
          border: '1px solid #1D1E1C',
          backgroundColor: 'var(--colorBackground)',
          padding: '8px',
        },

        '.Input--invalid': {
          color: 'var(--textColor)',
        },

        '.PickerItem': {
          border: '1px solid #BBBBBB',
          backgroundColor: 'var(--colorBackground)',
        },

        '.PickerItem:focus': {
          outline: '1px solid #1D1E1C',
          border: '1px solid #1D1E1C',
          backgroundColor: 'var(--colorBackground)',
        },

        '.PickerItem:hover': {
          border: '1px solid #737472',
          backgroundColor: 'var(--colorBackground)',
        },

        '.MenuIcon': {
          fill: '#1D1C1E',
        },

        '.MenuIcon:hover': {
          fill: '#1D1C1E',
        },

        '.Dropdown': {
          boxShadow:
            '0px 0.5px 1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.02), 0px 1.5px 3px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.07)',
          border: '1px solid #BBBBBB',
          borderRadius: '4px',
        },

        '.DropdownItem': {
          padding: '8px',
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          border: 'none',
        },

        '.DropdownItem:active': {
          backgroundColor: '#EEEEEE',
        },

        '.DropdownItem--highlight': {
          backgroundColor: '#EEEEEE',
        },
      },
    }

    this.elements = this.stripe.elements({
      appearance: appearance,
      clientSecret: this.client_secret,
    })
    let paymentMethodOrder = ['card', 'us_bank_account']
    if (options.preferred_payment_method == 'us_bank_account') {
      paymentMethodOrder.reverse()
    }
    this.payment_element = this.elements.create('payment', {
      paymentMethodOrder,
    })
    if (document.querySelector('#address-element')) {
      this.address_element = this.elements.create('address', {
        mode: 'billing',
      })
    }

    this.payment_element.on('ready', (event) => {
      document.querySelector('#js-pay-buttons').hidden = false
      if (this.address_element) {
        document.querySelector('#address-element').hidden = false
      }
    })

    this.payment_element.on('change', (event) => {
      const address_mounted =
        this.address_element &&
        document.querySelector('#address-element').childNodes.length !== 0
      if (event.value.type === 'card' && !address_mounted) {
        this.address_element?.mount('#address-element')
      } else if (event.value.type === 'us_bank_account' && address_mounted) {
        this.address_element?.unmount('#address-element')
      }
    })

    this.payment_error = document.getElementById('payment-error')
    this.submit_button = this.form.querySelector('button[type="submit"]')
    this.loading_indicator = this.form.querySelector('.js-loading')
    this.invoice_client_key = this.form.querySelector(
      'input[name="invoice_client_key"]'
    ).value
  }

  paymentReady(event) {
    return this.clearError()
  }

  paymentChanged(event) {
    if (event.error) {
      return this.displayError(event.error.message)
    } else {
      this.submit_button.disabled = !event.complete
      return this.clearError()
    }
  }

  clearError() {
    return (this.payment_error.textContent = '')
  }

  displayError(error) {
    this.submit_button.disabled = false
    this.loading_indicator.hidden = true
    return (this.payment_error.textContent = error)
  }

  submit(event, url) {
    this.submit_button.disabled = true
    this.loading_indicator.hidden = false
    event.preventDefault()
  }

  mount() {
    this.payment_element.mount('#payment-element')
    this.payment_element.addEventListener('ready', this.paymentReady)
    this.payment_element.addEventListener('change', this.paymentChanged)
    return this.form.addEventListener('submit', this.submit)
  }

  unmount() {
    this.form.removeEventListener('submit', this.submit)
    return this.payment_element.unmount('#payment-element')
  }
}

export const StripePaymentForm = class StripePaymentForm extends StripeElementsForm {
  constructor(options) {
    const stripe = Stripe(options.account_id, {
      stripeAccount: options.customer_account_id,
    })

    super(options, stripe)
  }

  submit(event) {
    super.submit(event)
    this.stripe
      .confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/stripe_connect/payments/fulfill`, // TODO: we don't currently support any payment methods that require redirects, but Stripe requires this param. this URL will not work currently, as it only supports a POST in the routes.
        },
        redirect: 'if_required',
      })
      .then((result) => {
        if (result.error) {
          this.displayError(result.error.message)
        } else {
          this.form.action = '/stripe_connect/payments/fulfill'
          this.form.querySelector('input[name="payment_intent_id"]').value =
            result.paymentIntent.id
          this.form.submit()
        }
      })
  }
}

export const StripeRecurringPaymentForm = class StripeRecurringPaymentForm extends StripeElementsForm {
  constructor(options) {
    const stripe_opts = { stripeAccount: options.customer_account_id }
    const stripe = Stripe(options.account_id, stripe_opts)

    super(options, stripe)
  }

  submit(event) {
    super.submit(event)
    this.stripe
      .confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/stripe_connect/payments/fulfill`, // TODO: we don't currently support any payment methods that require redirects, but Stripe requires this param. this URL will not work currently, as it only supports a POST in the routes.
        },
        redirect: 'if_required',
      })
      .then((result) => {
        if (result.error) {
          return this.displayError(result.error.message)
        } else {
          this.form.action = '/stripe_connect/recurring_payments'
          this.form.querySelector('input[name="payment_method_id"]').value =
            result.paymentIntent.payment_method
          this.form.querySelector('input[name="payment_intent_id"]').value =
            result.paymentIntent.id
          this.form.submit()
        }
      })
  }
}

export const StripeUpdatePaymentForm = class StripeUpdatePaymentForm extends StripeElementsForm {
  constructor(options) {
    const stripe_opts = { stripeAccount: options.customer_account_id }
    const stripe = Stripe(options.account_id, stripe_opts)

    super(options, stripe)
  }

  submit(event) {
    super.submit(event)
    this.stripe
      .confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/stripe_connect/payments/fulfill`, // TODO: we don't currently support any payment methods that require redirects, but Stripe requires this param. this URL will not work currently, as it only supports a POST in the routes.
        },
        redirect: 'if_required',
      })
      .then((result) => {
        if (result.error) {
          return this.displayError(result.error.message)
        } else {
          this.form.action = `/client/recurring_invoices/${this.invoice_client_key}/update_recurring_payment_settings`
          this.form.querySelector(
            'input[name="stripe_payment_method_id"]'
          ).value = result.setupIntent.payment_method
          this.form.submit()
        }
      })
  }
}
