import { csrfToken } from 'harvest'

import tips from './tips'

document.addEventListener('click', function ({ target }) {
  let button
  if (!(button = target.closest('[data-close-alert]'))) {
    return
  }
  button.closest('.pds-alert, .pds-card').hidden = true
  target.dispatchEvent(
    new CustomEvent('alert-close', {
      bubbles: true,
    })
  )
  if (button.dataset.closeAlert) {
    fetch(button.dataset.closeAlert, {
      method: 'post',
      credentials: 'same-origin', // required for some versions of Firefox/Safari
      headers: {
        accept: 'application/json',
        'x-csrf-token': csrfToken(),
      },
    }).catch((error) => {
      throw error
    })
  }
})

// If adding a new tip type, ensure it's also added to DismissedTip::VALID_TIP_NAMES
document.addEventListener('click', function ({ target }) {
  const name = target.closest('[data-dismiss-tip]')?.dataset.dismissTip

  if (name) {
    return tips.dismiss(name)
  }
})
