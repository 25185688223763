import { pageData } from 'harvest'

export function csrfParam() {
  return (
    pageData.csrf_param ||
    document.querySelector('meta[name="csrf-param"]')?.content
  )
}

export function csrfToken() {
  return (
    pageData.csrf_token ||
    document.querySelector('meta[name="csrf-token"]')?.content
  )
}
