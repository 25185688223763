import { createApi } from '@reduxjs/toolkit/query/react'
import { jsonQuery, pageData } from 'harvest'

export const exportsApi = createApi({
  reducerPath: 'exportsApi',
  baseQuery: jsonQuery(),
  endpoints: (builder) => ({
    createExport: builder.mutation({
      query: (body) => {
        const { export_type } = pageData

        return {
          url: `/exports/${export_type}`,
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useCreateExportMutation } = exportsApi
